<template>
  <v-container id="about" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0 main">
      <v-col cols="12" class="ma-auto pa-0">
        <v-row class="py-8">
          <v-col cols="12" md="9" lg="7" class="ma-auto">
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" class="ma-auto" order="2" order-md="1" data-aos="fade-right">
                <div class="custom-accent--text font-weight-medium text-h4">{{$t("about")}}</div>
                <h1 class="font-weight-medium custom-secondary--text">{{$t("whatmakesusdifferent")}}</h1>
                <p class="mt-6 custom-secondary--text">
                  {{$t("whatmakesusdifferentdesc1")}}
                  <br>
                  {{$t("whatmakesusdifferentdesc2")}}
                </p>
                <v-btn class="custom-accent" :title="$t('contact')" @click="scrollToId('contact')" dark depressed rounded>{{$t("contact")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
              </v-col>
              <v-col cols="12" md="6" order="1" order-md="2" data-aos="zoom-in">
                <v-img :src="storage_url+path+'about-1.png'" class="image-1" :alt="$t('about1alt')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="custom-secondary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="custom-accent white--text py-8">
          <v-col cols="12" md="9" lg="7" class="ma-auto">
              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="6" class="text-center ma-auto" data-aos="zoom-in">
                  <v-img :src="storage_url+path+'about-3.png'" class="image-3" :alt="$t('about3alt')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="custom-secondary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" md="6" class="ma-auto" data-aos="fade-left">
                  <div class="font-weight-medium pb-4 text-uppercase text-h4">{{$t("whatwedo")}}</div>
                  <div class="py-4">
                    {{$t("whatwedodesc")}}
                  </div>
                  <div class="">
                    <span><v-icon dark>mdi-chevron-right</v-icon>{{$t("whatwedo1")}}</span>
                    <br>
                    <span><v-icon dark>mdi-chevron-right</v-icon>{{$t("whatwedo2")}}</span>
                  </div>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <v-row class="py-8" data-aos="fade">
          <v-col cols="12" md="9" lg="7" class="ma-auto">
              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="6" class="ma-auto" order="2" order-md="1" data-aos="slide-right">
                  <who-we-are></who-we-are>
                </v-col>
                <v-col cols="12" md="6" order="1" order-md="2" data-aos="slide-left">
                  <v-img :src="storage_url+path+'about-2.png'" class="image-2" :alt="$t('about2alt')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="custom-secondary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <v-row data-aos="fade-up" class="py-12 custom-accent white--text">
          <v-col cols="12" md="9" lg="7" class="ma-auto">
            <our-values :isDark="true"></our-values>
          </v-col>
        </v-row>
        <v-row class="py-8" data-aos="zoom-in" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-8">
            <div class="text-h5">{{$t("interested")}}</div>
            <v-btn class="my-8" :title="$t('contact')" dark @click="$navigateToPath('/contact')" depressed rounded>{{$t("contact")}} {{$t("now")}}!<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
            <contact-us-form data-aos="fade-right" :isDark="false" :type="'about'"></contact-us-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../components/Contact/ContactUsForm.vue'
import ourvalues from '../components/Employer/OurValues.vue'
import whoweare from '../components/Employer/WhoWeAre.vue';
export default {
  name: "About",

  data() {
    return {
      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "about/images/",
    };
  },
  components: {
    'contact-us-form':contactusform,
    'our-values':ourvalues,
    'who-we-are':whoweare,
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    scrollToId(id){
      let element = document.getElementById(id);
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  },
};
</script>
<style scoped>
@import url('../assets/styles/about.css');
</style>